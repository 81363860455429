<template>
  <div>
    <div style="margin-bottom:20px;">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>个性推荐策略</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-card shadow="always">
      <div class="title">信息筛选</div>
      <div class="search-content">
        <div style="display:flex;marginBottom:20px">
          <div style="display:flex;align-items:center;marginRight:60px">
            <div style="width:100px;">策略名称</div>
            <el-input style="width:240px;" v-model.trim="strategyName" maxlength="10" placeholder="请输入策略名称" />
          </div>
          <div style="display:flex;align-items:center;marginRight:60px">
            <div style="width:100px;">创建人</div>
            <el-input style="width:240px;" v-model.trim="createManName" maxlength="10" placeholder="请输入创建人" />
          </div>
        </div>
        <div style="display:flex;">
          <div style="display:flex;align-items:center;marginRight:60px">
            <div style="width:100px;">最新数据日期</div>
            <!-- :disabled-date="setDisableDate" -->
            <el-date-picker style="width:240px;" value-format="YYYY-MM-DD" v-model="publishDate" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <div style="display:flex;align-items:center;marginRight:60px">
            <div style="width:100px;">更新方式</div>
            <el-select v-model="updateMethod" placeholder="请选择更新方式" style="width: 240px">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="search-btn" @click="search">筛选</div>
        </div>
      </div>
    </el-card>
    <el-card shadow="always" class="list-container">
      <div class="header">
        <div class="header__title">策略列表</div>
        <div class="header__btn" @click="addPolicy">+新增选股策略</div>
      </div>
      <div class="content">
        <el-table :data="tableData" :header-cell-style="{'text-align': 'center','font-weight': 'bold',color: '#333'}" :cell-style="{'text-align': 'center',color: '#333'}" style="width: 100%">
          <el-table-column type="index" label="序号" width='60' />
          <el-table-column prop="strategyName" label="策略名称" />
          <el-table-column prop="updateMethod" label="更新方式">
            <template #default="scope">
              <span>{{scope.row.updateMethod ==='1'?'自动重复':'手动单次'}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="policyStatus" label="当前状态">
            <template #default="scope">
              <span :class="scope.row.policyStatus ==='正常'?'normal':'abnormal'">{{scope.row.policyStatus}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="latestDate" label="最新数据日期" />
          <el-table-column prop="createTime" label="创建时间" />
          <el-table-column prop="createManName" label="创建人" />
          <el-table-column label="操作">
            <template #default="scope">
              <el-button @click="showDetail(scope.row)" type="text" size="small">查看</el-button>
              <el-button @click="deleteStrategy(scope.row)" type="text" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div class="page">
          <el-pagination v-model:currentPage="currentPage" :page-sizes="[5, 10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      policyTitle: '',
      publishDate: [],
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      total: 0,
      createManName: "",
      publishDate: [],
      // endDate: "",
      // startDate: "",
      strategyName: "",
      updateMethod: "",
      options: [],
    })

    onMounted(() => {
      getList()
      updateMethodEnum()
    })
    // 更新方式
    const updateMethodEnum = () => {
      proxy.$get("/cts/back/strategy/updateMethodEnum").then((res) => {
        if (res.resultState === "200") {
          state.options = res.data;
        }
      })
        .catch((err) => { });
    };
    const search = () => {
      getList()
    }
    const setDisableDate = (v) => {
      return (
        v.getTime() < new Date().getTime() - 24 * 60 * 60 * 1000 * 365 * 2 ||
        v.getTime() > new Date().getTime()
      )
    }
    const getList = () => {
      // let list = ['%', '_', '——']
      // let flag = false
      // list.forEach(item => {
      //   let index = state.strategyName.indexOf(item)
      //   if (index > -1) {
      //     flag = true
      //     return
      //   }
      // })
      // if (flag) {
      //   ElMessage({
      //     message: `不能包含特殊字符`,
      //     type: "warning",
      //   });
      //   state.strategyName = ''
      //   return
      // }
      let data = {
        content: {
          createManName: state.createManName,
          endDate: state.publishDate ? state.publishDate[1] || '' : '',
          startDate: state.publishDate ? state.publishDate[0] || '' : '',
          strategyName: state.strategyName,
          updateMethod: state.updateMethod
        },
        pageNo: state.currentPage,
        pageSize: state.pageSize
      }
      proxy.$post('/cts/back/strategy/page', data).then((res) => {
        if (res.resultState === '200') {
          state.tableData = res.data.list;
          state.total = res.data.total;
        }
      }).catch((err) => {

      });
    }

    const handleSizeChange = (val) => {
      state.pageSize = val;
      getList();
    }

    const handleCurrentChange = (val) => {
      state.currentPage = val;
      getList();
    }

    const addPolicy = () => {
      router.push({ path: '/content/contentManagement/addOrEdit', query: { type: 'add' } })
    }

    const showDetail = (data) => {
      router.push({ path: '/content/contentManagement/detail', query: { id: `${data.id}` } })
    }
    const deleteStrategy = (data) => {
      ElMessageBox.alert("确认删除该条数据吗?", "提示", {
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          let params = `?strategyId=${data.id}`
          proxy.$get("/cts/back/strategy/delete" + params).then((res) => {
            if (res.resultState === "200") {
              ElMessage({
                message: `${res.msg}`,
                type: "success",
              });
              getList();
            }
          })
            .catch((err) => { });
        })
        .catch(() => { });
      return;
    };
    const formatterTime = (row, column) => {
      let time = row.releaseDate;
      let month = '', date = '';
      if (time[1] < 10) {
        month = `0${time[1]}`
      } else {
        month = `${time[1]}`
      }
      if (time[2] < 10) {
        date = `0${time[2]}`
      } else {
        date = `${time[2]}`
      }

      return `${time[0]}-${month}-${date}`
    }

    return {
      ...toRefs(state),
      search,
      handleSizeChange,
      handleCurrentChange,
      addPolicy,
      showDetail,
      deleteStrategy,
      formatterTime,
      updateMethodEnum,
      setDisableDate
    }
  }
}
</script>

<style lang="scss" scoped>
.container-title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0 20px 0;
}
.title {
  font-weight: bold;
}
.search-content {
  margin-top: 10px;
  //   display: flex;
  //   justify-content: space-between;
  align-items: center;
  .search-box {
    display: flex;
    align-items: center;
    &__title {
      width: 130px;
    }
  }
  .search-btn {
    background: rgb(56, 162, 138);
    width: 80px;
    padding: 8px 0;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    margin-left: 150px;
  }
}
.list-container {
  margin-top: 20px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    &__title {
      font-weight: bold;
    }
    &__btn {
      background: rgb(56, 162, 138);
      width: 110px;
      padding: 8px 0;
      border-radius: 6px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
    }
  }
  .content {
    margin-top: 15px;
    .normal {
      color: #81b337;
    }
    .abnormal {
      color: #bd3124;
    }
    .page {
      float: right;
      margin: 20px;
    }
  }
}
</style>